import React from 'react';

const RightPart = ({ sections }) => {
    return (
        <div className="md:w-2/3">
            {sections.map((section, i) => (
                <div key={i} className="mb-6">
                    <section className="mb-6">
                        <h2 className="mb-4">{section.title}</h2>

                        {section.elements.map((job, j) => (
                            job.type === 'pdf-empty-space' ? (
                                <div key={j} className="pdf-only-space" style={{ height: job.size || 0 }}></div>
                            ) : (
                                <article key={j} className="mb-6">
                                    {/* Gray Background Box for Job Title, Employer, and Date */}
                                    <div className="bg-gray-100 py-4 px-8 mb-4 -mx-8">
                                        <h3>{job.title}</h3>
                                        <p className="text-lg leading-tight">{job.employer}</p>
                                        <p className="font-light text-lg leading-tight">{job.dates}</p>
                                    </div>

                                    {/* Job Descriptions */}
                                    {job.responsibilities.length > 0 && (
                                        <ul className="list-disc list-outside ml-6 space-y-1">
                                            {job.responsibilities.map((responsibility, respIndex) => (
                                                <li key={respIndex}>
                                                    {responsibility.includes('www.') ? (
                                                        <>
                                                            {responsibility.split('www.')[0]}
                                                            <a
                                                                href={`https://www.${responsibility.split('www.')[1]}`}
                                                                className="text-yellow-500 hover:underline"
                                                            >
                                                                www.{responsibility.split('www.')[1]}
                                                            </a>
                                                        </>
                                                    ) : (
                                                        responsibility
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </article>
                            )
                        ))}
                    </section>
                    {i < sections.length - 1 && (
                        <div className="block md:hidden border-t-[0.5px] border-orange-400 w-full h-px bg-white mt-4 mb-7 mr-4 -ml-8"></div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RightPart;
