import React, { useState, useEffect } from 'react';
import { BarsArrowUpIcon } from '@heroicons/react/24/solid';

const ScrollToTopButton = ({className}) => {
  const [isVisible, setIsVisible] = useState(false);


  // Show or hide the button based on scroll position
  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top of the page with a faster animation
  const scrollToTop = () => {
    // Stop any ongoing scroll animation
    window.scrollTo(window.scrollX, window.scrollY);

    const duration = 300; // Adjust this value to control speed (lower = faster)
    const start = window.scrollY;
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    const scroll = () => {
      const now = 'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, (now - startTime) / duration);
      window.scrollTo(0, Math.ceil((1 - time) * start));

      if (time < 1) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className={`scroll-to-top ${className}`}>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-20 sm:bottom-4 right-4 bg-gray-50 text-primary-color py-2 px-2 rounded-full shadow-md"
          aria-label="Scroll to top"
        ><BarsArrowUpIcon className="size-5" /></button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
