// src/App.jsx
import React from 'react';
import Resume from './Resume';

const App = () => {
  return (
    <div>
      <Resume />
    </div>
  );
};

export default App;
